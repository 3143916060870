.errorResponse{
    width: 80%;
    margin: 10px auto;
    padding: 10px;
    background-color: cornsilk;
    border: 1px solid goldenrod;
    border-radius: 2px;
}

.errorResponse ul{
    padding: 0;
    margin: 0;
    list-style: none;
}