.article {
    box-sizing: border-box;
}

.welcome {
    font-size: 41pt;
    position: absolute;
    color: goldenrod;
    width: 100%;
}

.half, .third, .quarter{
    width: 100%;
    
}

@media(min-width: 500px) {
    .half {
        width: 50%;
    }

    .third {
        width: 33%;
    }

    .quarter {
        width: 25%;
    }

    .welcome{
        font-size: 41pt;
    }
}