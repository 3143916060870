.feed{
    min-width: 100%;
    height: 500px;
    overflow: scroll;
    display: flex;
}

.post{
    height: 100%;
    position: relative;
    text-align: center;
}

.post img {
    max-width: none;
}

.text{
    padding: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgb(0, 0, 0, 0.8);
    color: goldenrod;
    font-size: 21pt;
    transition: all 0.3s ease-out;
    opacity: 0;
}

.post:hover .text, .post:focus .text{
    opacity: 1;
}