.carousel{
    width: 100%;
    max-width: 1200px;
    height: 600px;
    overflow: hidden;
    background-color: gainsboro;
    margin: 0 auto;
}

.frame{
    display: flex;
    position: relative;
    width: 300%;
    height: 100%;
}

.picture{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.pic1{
    width: 100%;
    height: 100%;
    background-image: url('/images/willow-full.jpg');
    background-size: cover;
    background-position: center;
}

.pic2{
    width: 100%;
    height: 100%;
    background-image: url('/images/bella2-full.jpg');
    background-size: cover;
    background-position: center;
}

.pic3{
    width: 100%;
    height: 100%;
    background-image: url('/images/purdey2.jpg');
    background-size: cover;
    background-position: center;
}

.controls{
    position: absolute;
    width: 100%;
    max-width: 1200px;
    height: 600px;
    height: initial;
    display: flex;
    background: none;
    justify-content: space-between;
    z-index: 200;
}

.left{
    float: left;
}

.right{
    float:right;
    text-align: right;
}

.control{
    width: 20%;
    padding: 40px;
    cursor: pointer;
    font-family: 'Londrina Outline', cursive;
    font-size: 45pt;
    color: white;
    opacity: 0.8;
}

.control:hover{
    color: goldenrod;
}

.disabled{
    cursor: progress;
    color: #333;
}