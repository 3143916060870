.container{
  width: 100%;
  box-sizing: border-box;
  padding-top: 25px;
}

  .container h1{
    padding: 10px 0;
    margin: 0px 20px;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 31pt;
  }

  .container .contents{
      padding: 10px 20px;
  }