
#nav{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    /*background-color: rgb(256,256,256, 0.6);*/
}

.hamburger{
    margin: 0 auto;
    display: table;
}



.bun {
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
}


.hamburger:hover .bun{
    background-color: goldenrod;
}

#nav button{
    background-color: inherit;
    border: 0;
    outline: none;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
}

 #nav button:hover{
     color: goldenrod;
 }

#nav a{
    color: black;
    text-decoration: none;
}

#nav a:hover{
    color: goldenrod;
}

#nav ul{
    list-style: none;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0px 10px 10px 10px;
    border-width: 0px 0px 3px 0;
    display: none;
    font-size: 14pt;
    font-family: 'PT Serif', serif;
}

#nav li{
    padding: 10px 0px;
    box-sizing: border-box;
    margin: 0 50px;
    text-align: center;
}

.margin{
    width: 100%;
    height: 60px;
}

.underline{
    width: 90%;
    margin: 0 auto 10px;
    border-top: 1px solid black;
    transition: 1s ease-in-out;
}

#nav:hover .underline{
    border-top: 1px solid goldenrod;
}

@media(min-width:750px){
    .hamburger{
        display: none;
    }

    #nav ul {
        display:table;
    }

    #nav li{
        display: inline-block;
    }
}