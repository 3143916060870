.hover{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.info{
    height: 100%;
    transform: translateY(75%);
    background-color: rgb(0, 0, 0, 0.8);
    color: white;
    transition: all 0.3s ease-out; 
}

.title{
    font-family: 'PT Serif', serif;
    font-size: 21pt;
    padding: 0px 10px;
    height: 20%;
    box-sizing: border-box;
    color: goldenrod;
}

.text{
    height: 80%;
    box-sizing: border-box;
    padding: 10px;
}

.showing{
   transform: translateY(0%); 
}