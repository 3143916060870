@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=PT+Serif&family=Londrina+Outline&display=swap);
html{
  min-height:100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6{
    /*font-family: 'PT Serif', serif;*/
}

.claustrophobic{
    margin: 40px 0 15px 0;
}

.rounded{
  border-radius: 25px;
  border: 5px solid white;
  box-sizing: border-box;
}

.shadowed{
  box-shadow: 2px 2px 4px #666;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.underlined{
  border-bottom: 2px solid goldenrod;
}

.floatRight{
  float: right;
  margin: 15px 0 15px 15px;
}

.floatLeft{
  float: right;
  margin: 15px 15px 15px 0;
}

img{
  max-width: 100%;
}

.textpane{
  padding: 30px;
}

.heading{
  color: goldenrod;
  font-size: 31pt;
}
.tumblr_feed__1Xjz3{
    min-width: 100%;
    height: 500px;
    overflow: scroll;
    display: flex;
}

.tumblr_post__1aMtA{
    height: 100%;
    position: relative;
    text-align: center;
}

.tumblr_post__1aMtA img {
    max-width: none;
}

.tumblr_text__3Yo8F{
    padding: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgb(0, 0, 0, 0.8);
    color: goldenrod;
    font-size: 21pt;
    transition: all 0.3s ease-out;
    opacity: 0;
}

.tumblr_post__1aMtA:hover .tumblr_text__3Yo8F, .tumblr_post__1aMtA:focus .tumblr_text__3Yo8F{
    opacity: 1;
}
.container_container__2g2SU{
  width: 100%;
  box-sizing: border-box;
  padding-top: 25px;
}

  .container_container__2g2SU h1{
    padding: 10px 0;
    margin: 0px 20px;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 31pt;
  }

  .container_container__2g2SU .container_contents__1rYe_{
      padding: 10px 20px;
  }
.landing_landing__1gNcy{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
}

.logo_logo__253PV{
    height: 400px;
    width: 400px;
    margin: auto;
    background-position: center;
    background-size: contain;
    background-image: url('/images/logo.png');
}
.section_section__ibNI2{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

@media(min-width:500px) {
    .section_section__ibNI2{
        flex-wrap: nowrap;
        margin: 10px auto;
    }
}

.section_buffered__23V8W{
    width: 90%;
    max-width: 1080px;
    margin: 0 auto;
}

.section_full__N5aQN{
    width: 100%;
}

.article_article__1iFsk {
    box-sizing: border-box;
}

.article_welcome__2nQfn {
    font-size: 41pt;
    position: absolute;
    color: goldenrod;
    width: 100%;
}

.article_half__tiQ5G, .article_third__2-PmS, .article_quarter__3ASV2{
    width: 100%;
    
}

@media(min-width: 500px) {
    .article_half__tiQ5G {
        width: 50%;
    }

    .article_third__2-PmS {
        width: 33%;
    }

    .article_quarter__3ASV2 {
        width: 25%;
    }

    .article_welcome__2nQfn{
        font-size: 41pt;
    }
}
.carousel_carousel__3fJdk{
    width: 100%;
    max-width: 1200px;
    height: 600px;
    overflow: hidden;
    background-color: gainsboro;
    margin: 0 auto;
}

.carousel_frame__3EnEo{
    display: flex;
    position: relative;
    width: 300%;
    height: 100%;
}

.carousel_picture__1nZKo{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.carousel_pic1__18rQX{
    width: 100%;
    height: 100%;
    background-image: url('/images/willow-full.jpg');
    background-size: cover;
    background-position: center;
}

.carousel_pic2__22PfG{
    width: 100%;
    height: 100%;
    background-image: url('/images/bella2-full.jpg');
    background-size: cover;
    background-position: center;
}

.carousel_pic3__1aMxw{
    width: 100%;
    height: 100%;
    background-image: url('/images/purdey2.jpg');
    background-size: cover;
    background-position: center;
}

.carousel_controls__2W4KW{
    position: absolute;
    width: 100%;
    max-width: 1200px;
    height: 600px;
    height: initial;
    display: flex;
    background: none;
    justify-content: space-between;
    z-index: 200;
}

.carousel_left__3jwoa{
    float: left;
}

.carousel_right__231Cw{
    float:right;
    text-align: right;
}

.carousel_control__zB1iy{
    width: 20%;
    padding: 40px;
    cursor: pointer;
    font-family: 'Londrina Outline', cursive;
    font-size: 45pt;
    color: white;
    opacity: 0.8;
}

.carousel_control__zB1iy:hover{
    color: goldenrod;
}

.carousel_disabled__1EK7C{
    cursor: progress;
    color: #333;
}
.hover_hover__3JKMr{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.hover_info__2-bRt{
    height: 100%;
    -webkit-transform: translateY(75%);
            transform: translateY(75%);
    background-color: rgb(0, 0, 0, 0.8);
    color: white;
    transition: all 0.3s ease-out; 
}

.hover_title__2gfzy{
    font-family: 'PT Serif', serif;
    font-size: 21pt;
    padding: 0px 10px;
    height: 20%;
    box-sizing: border-box;
    color: goldenrod;
}

.hover_text__2s5qR{
    height: 80%;
    box-sizing: border-box;
    padding: 10px;
}

.hover_showing__38ij8{
   -webkit-transform: translateY(0%);
           transform: translateY(0%); 
}
.title_title__3ybfn{
    padding: 10px;
}

.title_title__3ybfn h1{
    font-size: 41pt;
    text-align: center;
    color: goldenrod;
    font-family: 'Londrina Outline', cursive;
}

#topNav_nav__NxTb3{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    /*background-color: rgb(256,256,256, 0.6);*/
}

.topNav_hamburger__2E0Zz{
    margin: 0 auto;
    display: table;
}



.topNav_bun__2phWk {
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
}


.topNav_hamburger__2E0Zz:hover .topNav_bun__2phWk{
    background-color: goldenrod;
}

#topNav_nav__NxTb3 button{
    background-color: inherit;
    border: 0;
    outline: none;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
}

 #topNav_nav__NxTb3 button:hover{
     color: goldenrod;
 }

#topNav_nav__NxTb3 a{
    color: black;
    text-decoration: none;
}

#topNav_nav__NxTb3 a:hover{
    color: goldenrod;
}

#topNav_nav__NxTb3 ul{
    list-style: none;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0px 10px 10px 10px;
    border-width: 0px 0px 3px 0;
    display: none;
    font-size: 14pt;
    font-family: 'PT Serif', serif;
}

#topNav_nav__NxTb3 li{
    padding: 10px 0px;
    box-sizing: border-box;
    margin: 0 50px;
    text-align: center;
}

.topNav_margin__-rM9i{
    width: 100%;
    height: 60px;
}

.topNav_underline__1tQdK{
    width: 90%;
    margin: 0 auto 10px;
    border-top: 1px solid black;
    transition: 1s ease-in-out;
}

#topNav_nav__NxTb3:hover .topNav_underline__1tQdK{
    border-top: 1px solid goldenrod;
}

@media(min-width:750px){
    .topNav_hamburger__2E0Zz{
        display: none;
    }

    #topNav_nav__NxTb3 ul {
        display:table;
    }

    #topNav_nav__NxTb3 li{
        display: inline-block;
    }
}
.enquiryForm_errorResponse__22t2y{
    width: 80%;
    margin: 10px auto;
    padding: 10px;
    background-color: cornsilk;
    border: 1px solid goldenrod;
    border-radius: 2px;
}

.enquiryForm_errorResponse__22t2y ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
.input_row__3k_-o{
    width: 100%;
    display: flex;
}

.input_label__4f98M{
    padding: 10px 0;
    display: table-column;
    width: 30%;
    box-sizing: border-box;
}

.input_input__1XBda {
    width: 70%;
    padding: 10px 0;
    border: 1px solid #666; 
    border-width: 0 0 1px 0;
    background: none;
    margin: 5px;
    box-sizing: border-box;
    transition: 1s ease-in-out;
    font-family: inherit;
}

input:focus{
    border-color: goldenrod;
    outline: none;
}

.input_required__3v71p {
    color: goldenrod;
    padding: 0 1px;
}
.button_button__Y6CqJ{
    padding: 10px 20px;
    margin: 10px;
    border-radius: 0.5em;
    box-shadow: 3px 3px 3px #333;
    cursor: pointer;
    background-color: black;
    color: goldenrod;
    transition: 0.1s ease-in-out;
    border: 1px solid white;
    font-size: 11pt;
}

.button_button__Y6CqJ:focus{
    box-shadow: none;
    outline: none;
}

.button_danger__2g-3e{
    background-color: lightcoral;
}

.button_success__QhmEK {
    background-color: lightskyblue;
}
.App{
  
}
