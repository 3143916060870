.row{
    width: 100%;
    display: flex;
}

.label{
    padding: 10px 0;
    display: table-column;
    width: 30%;
    box-sizing: border-box;
}

.input {
    width: 70%;
    padding: 10px 0;
    border: 1px solid #666; 
    border-width: 0 0 1px 0;
    background: none;
    margin: 5px;
    box-sizing: border-box;
    transition: 1s ease-in-out;
    font-family: inherit;
}

input:focus{
    border-color: goldenrod;
    outline: none;
}

.required {
    color: goldenrod;
    padding: 0 1px;
}