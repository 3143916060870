.button{
    padding: 10px 20px;
    margin: 10px;
    border-radius: 0.5em;
    box-shadow: 3px 3px 3px #333;
    cursor: pointer;
    background-color: black;
    color: goldenrod;
    transition: 0.1s ease-in-out;
    border: 1px solid white;
    font-size: 11pt;
}

.button:focus{
    box-shadow: none;
    outline: none;
}

.danger{
    background-color: lightcoral;
}

.success {
    background-color: lightskyblue;
}