.section{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

@media(min-width:500px) {
    .section{
        flex-wrap: nowrap;
        margin: 10px auto;
    }
}

.buffered{
    width: 90%;
    max-width: 1080px;
    margin: 0 auto;
}

.full{
    width: 100%;
}
