@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=PT+Serif&family=Londrina+Outline&display=swap');

html{
  min-height:100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6{
    /*font-family: 'PT Serif', serif;*/
}

.claustrophobic{
    margin: 40px 0 15px 0;
}

.rounded{
  border-radius: 25px;
  border: 5px solid white;
  box-sizing: border-box;
}

.shadowed{
  box-shadow: 2px 2px 4px #666;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.underlined{
  border-bottom: 2px solid goldenrod;
}

.floatRight{
  float: right;
  margin: 15px 0 15px 15px;
}

.floatLeft{
  float: right;
  margin: 15px 15px 15px 0;
}

img{
  max-width: 100%;
}

.textpane{
  padding: 30px;
}

.heading{
  color: goldenrod;
  font-size: 31pt;
}